import { mapToArray } from '../..';
import { PaymentMethod } from '../../../types/types';
import { SlotService, SlotServices } from '../../state/types';

export const shouldDisplayPolicyWarning = ({
  slotServices,
  isServiceInCart,
  isUpsellPluginInstalled,
}: {
  slotServices: SlotServices;
  isServiceInCart: boolean;
  isUpsellPluginInstalled: boolean;
}) => {
  const services = mapToArray<SlotService>(slotServices);
  return (
    !isServiceInCart &&
    !isUpsellPluginInstalled &&
    services.length === 1 &&
    services[0].selectedPaymentType !== PaymentMethod.ONLINE &&
    (services[0].service.hasCancellationFee ||
      services[0].service.hasSaveCreditCardPolicy)
  );
};
